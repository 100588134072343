import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.css']
})
export class ModalConfirmComponent implements OnInit {

  @Input() Title: string;
  @Input() Body: string;
  @Input() Items: any[];
  @Input() showOKButton = true;
  @Input() showCancelButton = true;
  @Input() OtherMaxLength = 0;
  @Input() TableData = [];
  @Input() TableHeaderVisible = true;

  @Output() ok = new EventEmitter();
  @Output() cancel = new EventEmitter();

  @ViewChild('closeButton', {static: true}) closeButton;
  @ViewChild('modal', {static: true}) modal;

  Other = '';
  SelectedItem = '';

  zero() {
    return 0;
  }

  show() {
    this.SelectedItem = '';
    this.Other = '';
    $(this.modal.nativeElement).modal({
      backdrop: 'static',
      keyboard: false,
      focus: true,
      show: true
    });

    // https://stackoverflow.com/a/51824269/670465
    $(this.modal.nativeElement).find('.modal-header').css('cursor', 'move').on('mousedown', function(mousedownEvt) {
      const $draggable = $(this);
      const x = mousedownEvt.pageX - $draggable.offset().left;
      const y = mousedownEvt.pageY - $draggable.offset().top;
      $('body').on('mousemove.draggable', (mousemoveEvt) => {
          $draggable.closest('.modal-dialog').offset({
              left: mousemoveEvt.pageX - x,
              top: mousemoveEvt.pageY - y
          });
      });
      $('body').one('mouseup', _ => {
          $('body').off('mousemove.draggable');
      });
      $draggable.closest('.modal').one('bs.modal.hide', _ => {
          $('body').off('mousemove.draggable');
      });
  });
    // $(this.modal.nativeElement).on('hide.bs.modal', e => {
    //   this.cancel.emit();
    // });
  }

  ShowItems(): boolean {
    return !!this.Items && this.Items.length > 0;
  }
  ShowOtherOption(): boolean {
    return this.ShowItems() && this.SelectedItem.toLowerCase() === 'other';
    // && this.Items.map(x => (x || '').toLowerCase()).indexOf('other') === 0;
  }
  EnableOK(): boolean {
    return !this.ShowItems() || (!!this.SelectedItem && this.SelectedItem !== 'Other') || this.SelectedItem === 'Other' && !!this.Other;
  }

  clickedCancel() {
    this.cancel.emit();
    $(this.modal.nativeElement).modal('hide');
  }
  clickedOK() {
    if (this.ShowItems) {
      this.ok.emit(this.SelectedItem === 'Other' ? ('Other: ' + this.Other) : this.SelectedItem);
    } else {
      this.ok.emit();
    }
    $(this.modal.nativeElement).modal('hide');
  }


  constructor() { }

  ngOnInit() {
  }

}
