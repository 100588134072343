import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-section-business',
  templateUrl: './section-business.component.html',
  styleUrls: ['./section-business.component.css']
})
export class SectionBusinessComponent implements OnInit {

  errorList;
  @Input() values: any = {};
  @Output() valuesChange = new  EventEmitter();
  /* LegalName;
  FEIN;
  SSN;
  IDType;
  Address;
  City;
  State;
  Zip;
  BizPhone;
  BizFax;
  BizStartDate;

  Contact1Name;
  Contact1Title;
  Contact1Phone;
  Contact1Cell;
  Contact1Email;

  BizType;
  Bankruptcy;
  TypeOfIndustry; */

  bizTypes = [
    'Corporation',
    'C Corp',
    'S Corp',
    'Partnership',
    'Proprietor',
    'LLC',
  ];


  constructor(private api: ApiService) { }

  ngOnInit() {
  }

  change() {
    this.valuesChange.emit(this.values);
  }

  zipChange(event) {
    if (event && event.length >= 5) {
      this.api.GetZipCode(event).subscribe((x: any) => {
        if (x && x.ZipCode) {
          this.values.City = x.City;
          this.values.State = x.State;
        }
      });
    }
  }

}
