import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { ReturnStatement } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  apiUrl = environment.apiUrl; // 'https://dsp.p1fs.com/'; //  'http://localhost:64508/creditapp/commercial/';

  constructor(private http: HttpClient) {

  }

  public LogEvent(EventType: string,  DealID: number) {
    return this.http.get(this.apiUrl + 'CreditApp/Commercial/Log/' + EventType + '/?DealID=' + DealID);
  }
  public PostCommercialCreditApp(data: any) {
    return this.http.post(this.apiUrl + 'creditapp/commercial', data);
  }
  public GetZipCode(data: any) {
    return this.http.get(this.apiUrl + 'util/getzipcode?zipcode=' + data);
  }
  public Sign(data: any) {
    return this.http.post(this.apiUrl + 'util/sign', data);
  }
  public GetDeal(id) {
    return this.http.get(this.apiUrl + 'deal/' + id + '/Index');
  }
  public AlertEQF(subject:string, body:string) {
    return this.http.post(this.apiUrl + 'Util/SendMail', {
      From: null,
      To: 'whampton@p1fs.com',
      Subject: subject,
      Body: body,
      isHtml: true
    });
  }
}
