import { Component, OnInit, ViewChild, ɵCompiler_compileModuleAndAllComponentsAsync__POST_R3__, Input } from '@angular/core';
import { ApiService } from '../api.service';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ActivatedRoute, RoutesRecognized, Router } from '@angular/router';
import { Observable, of, forkJoin } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-simple-sign',
  templateUrl: './simple-sign.component.html',
  styleUrls: ['./simple-sign.component.css']
})
export class SimpleSignComponent implements OnInit {
  @ViewChild('ModalDone', {static: false}) modalDone: ModalConfirmComponent;
  @ViewChild('ModalIncompleteSignatures', {static: false}) ModalIncompleteSignatures: ModalConfirmComponent;


  signatureData = {};
  sending = false;
  expectedSignatures = [];
  DealID = 0;
  signerRoles: any = {};
  SignatureNeededLocator = 0;

  oopsTitle = `<span class="text-danger"><i class="fa fa-exclamation-triangle"></i> Oops</span>`;

  send(data) {
    if ($('.ng-invalid').length) {
      $('.ng-invalid').addClass('.ng-touched');
      this.ModalIncompleteSignatures.show();
    } else {
      const sigs: any = [];
      for (let i = 1; i < 100; i++) {
        const sig = this.signatureData['Signature' + i + 'Text'];
        const role = this.signerRoles['Signer' + i + 'Role'];
        if (sig) {
          sigs.push(
            {
              Source: 'CommercialCreditApp SimpleSign',
              LegalID: 4,
              DealID: this.DealID,
              SignatureText: sig,
              SignerRole: role || '',
              SignatureNeededLocator: this.SignatureNeededLocator
            }
          );
        }
      }

      const obs = sigs.map(x => this.api.Sign(x));
      obs.push(this.api.LogEvent('Signed', this.DealID));
      forkJoin(obs).subscribe(x => {
        this.modalDone.show();
      });



    }
  }



  constructor(private api: ApiService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((x: any) => {
      const qsID = (x.params.DealID || x.params.ID || x.params.id || x.params.Id || '').toLowerCase().trim();
      const obs = qsID ? this.api.GetDeal(qsID) : of(null);
      obs.subscribe((deal: any) =>  {
        // record DealID if we were able to get it
        if (deal && deal.DealID) {
          this.DealID = deal.DealID;

          // if ID comes in as guid and it is not the alternate deal, then it has to be signatureNeededID
          const altDealID = (deal.AlternateDealID || '').toLowerCase().trim();
          if (qsID.indexOf('-') > -1 && altDealID !== qsID) {
            this.SignatureNeededLocator = qsID;
          }
        }

        // start by using query string expected signs and if none present then try deal info if available
        if (x.params.expectedSignatures) {
          this.expectedSignatures = x.params.expectedSignatures
            .split('|')
            .filter(y => y)
            .map((z, index) => {
              const arr = z.split(':').map(b => (b || '').trim());
              const text = arr[0];
              const role = arr[1];
              this.signerRoles['Signer' + (index + 1) + 'Role'] = role;
              return text || '';
            });


        } else {
          if (deal && deal.Buyer && deal.Buyer.FullName) {
            this.expectedSignatures.push(deal.Buyer.FullName);
            this.signerRoles.Signer1Role = 'Buyer';
          }
          if (deal && deal.CoBuyer && deal.CoBuyer.FullName) {
            this.expectedSignatures.push(deal.CoBuyer.FullName);
            this.signerRoles.Signer2Role = 'CoBuyer';
          }
        }


      });

    });
  }

}
