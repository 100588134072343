import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-section-principal',
  templateUrl: './section-principal.component.html',
  styleUrls: ['./section-principal.component.css']
})
export class SectionPrincipalComponent implements OnInit {

  /*
  Principal1FirstName;
  Principal1MiddleName;
  Principal1LastName;
  Principal1Generation;
  Principal1Address;
  Principal1City;
  Principal1State;
  Principal1Zip;
  Principal1Phone;
  Principal1Cell;
  Principal1Email;
  Principal1SSN;
  Principal1DOB;
  Principal1Citizen;
  Principal1Title;
  Principal1Ownership;

  Principal2FirstName;
  Principal2MiddleName;
  Principal2LastName;
  Principal2Generation;
  Principal2Address;
  Principal2City;
  Principal2State;
  Principal2Zip;
  Principal2Phone;
  Principal2Cell;
  Principal2Email;
  Principal2SSN;
  Principal2DOB;
  Principal2Citizen;
  Principal2Title;
  Principal2Ownership;
  */

  @Input() values: any = {};
  @Output() valuesChange = new  EventEmitter();

  change() {
    this.valuesChange.emit(this.values);
  }
  changeZip(whichone) {
    const event = this.values[whichone + 'Zip'];
    if (event && event.length >= 5) {
      this.api.GetZipCode(event).subscribe((x: any) => {
        if (x && x.ZipCode) {
          this.values[whichone + 'City'] = x.City;
          this.values[whichone + 'State'] = x.State;
        }
      });
    }
  }
  constructor(private api: ApiService) { }

  ngOnInit() {
  }

}
