import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ApiService } from '../api.service';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { Observable, observable, forkJoin } from 'rxjs';


declare var $: any;


@Component({
  selector: 'app-credit-app',
  templateUrl: './credit-app.component.html',
  styleUrls: ['./credit-app.component.css']
})
export class CreditAppComponent implements OnInit {

  @ViewChild('modalAfterSubmit', {static: false}) modalAfterSubmit: ModalConfirmComponent;
  @ViewChild('modalErrorRequiredFields', {static: false}) modalErrorRequiredFields: ModalConfirmComponent;
  creditAppData: any = {};
  dealID = 0;
  SecureFileTransferLocator = null;
  sending = false;

  constructor(private api: ApiService) { }

  ngOnInit() {
  }

  getExpectedSignatures() {
    const res = [];
    const p1Full = this.getName(this.creditAppData, 'Principal1');
    if (p1Full) {
      res.push(p1Full);
    }
    const p2Full = this.getName(this.creditAppData, 'Principal2');
    if (p2Full) {
      res.push(p2Full);
    }

    if (res.length === 0) {
      res.push('');
    }

    return res;
  }

  getName(data, prefix) {
    const f = (data[prefix + 'FirstName'] || '');
    const l = (data[prefix + 'LastName'] || '');
    let full = f + ' ' + l;
    full = full.replace(/\s{2,}}/ig, ' ').trim();

    return full;
  }

  send(data) {

    if (this.creditAppData && this.creditAppData.Signature1Text && this.creditAppData.LegalName) {
      this.sending = true;
      this.api.PostCommercialCreditApp(data).subscribe((x: any) => {

        const tasks = [];
        this.dealID = x.DealID;
        this.SecureFileTransferLocator = x.SecureFileTransferLocator;
        tasks.push(this.api.LogEvent('Submitted', this.dealID));
        tasks.push(this.api.LogEvent('Signed', this.dealID));
        tasks.push(this.api.Sign(
        {
          DealID: this.dealID,
          SignatureText: this.creditAppData.Signature1Text,
          SignerRole: 'Buyer',
          Source: 'CommercialCreditApp'
        }));
        if (this.creditAppData.Signature2Text) {
          tasks.push(this.api.Sign(
          {
            DealID: this.dealID,
            SignatureText: this.creditAppData.Signature2Text,
            SignerRole: 'CoBuyer',
            Source: 'CommercialCreditApp'
          }));
        }
        forkJoin(tasks).subscribe(x => {
          this.sending = false;
          this.modalAfterSubmit.show();
        });

      });
    } else {
      $('.form-control').addClass('ng-touched');
      $([document.documentElement, document.body]).animate({
        scrollTop: $('.ng-invalid.ng-touched').first().offset().top - 200
      }, 400, 'swing', _ => this.modalErrorRequiredFields.show() );
    }


  }

  modalOK() {
    window.location.href = 'https://securefiletransfer.p1eqf.com/login/' + this.SecureFileTransferLocator;
  }
  modalCancel() {

  }

}
