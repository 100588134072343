import { Component, OnInit, Input, HostBinding, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgModel, MaxLengthValidator, RequiredValidator, FormControl, Validators } from '@angular/forms';
declare var $: any;

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css']
})
export class InputTextComponent implements OnInit {

  @HostBinding('class')
  get parentClasses() {
    return [
      'col-md-' + this.columnWidth
    ].join(' ');
  }


  @Input() inputModel;
  @Output() inputModelChange = new EventEmitter();
  @Input() label;
  @Input() columnWidth;
  @Input() placeholder;
  @Input() maxlength;
  @Input() isBoolean = false;
  @Input() class = '';

  @Input() required = false;
  @Input() errorList = {};


  @Input() focusedClass = '';
  @Input() blurredClass = '';
  @Input() tooltipText = '';

  mainInputHasFocus = false;
  mainInput = new FormControl('');
  @ViewChild('mainElement', {static: true}) mainElement;



  constructor() { }

  ngOnInit() {
    if (this.required) {
      this.mainInput.setValidators([Validators.required]);
    }
    if (this.tooltipText) {
      $(this.mainElement.nativeElement).tooltip({title: this.tooltipText, trigger: 'focus'});
    }
  }

  // public isValid(): boolean {
  //   let ret = true;
  //   delete this.errorList[this.inputModel];
  //   if (this.required && this.mainInput.touched && (this.mainInput.value || '').trim().length === 0) {
  //     ret = false;
  //   }
  //   return ret;
  // }

  change(newVal) {
    let valToReturn = newVal;
    if (this.isBoolean) {
      // const s = (String(newVal) || '').toLowerCase().trim();
      // valToReturn = s.startsWith('t') || s === '1' || s.startsWith('y');
    }
    this.inputModelChange.emit(valToReturn);
  }

  getInputClass() {
    return this.mainInputHasFocus ? this.focusedClass : this.blurredClass;
  }




}
