import { Component, OnInit, Input, HostBinding, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { NgModel, MaxLengthValidator, RequiredValidator, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-input-dropdown',
  templateUrl: './input-dropdown.component.html',
  styleUrls: ['./input-dropdown.component.css']
})
export class InputDropdownComponent implements OnInit {

  @HostBinding('class')
  get parentClasses() {
    return [
      'col-md-' + this.columnWidth
    ].join(' ');
  }


  @Input() inputModel;
  @Output() inputModelChange = new EventEmitter();
  @Input() display = '';
  @Input() label;
  @Input() columnWidth;
  @Input() placeholder;
  @Input() maxlength;
  @Input() isBoolean = false;
  @Input() options: any = [];
  @Input() usStates = false;

  @Input() required = false;
  @Input() errorList = {};


  @Input() focusedClass = '';
  @Input() blurredClass = '';

  mainInputHasFocus = false;
  //mainInput = new FormControl('');

  allStates =  [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY'
  ];


  constructor() { }

  ngOnInit() {
    if (this.required) {
      //this.mainInput.setValidators([Validators.required]);
    }
    if (this.usStates) {
      this.options = this.allStates;
    }
  }


  change(newVal) {
    const val = this.hasOwnProperty(newVal, 'value') ? newVal.value : newVal;
    this.display = this.hasOwnProperty(newVal, 'label') ? newVal.label : newVal;
    this.inputModel = val;
    this.inputModelChange.emit(val);
  }

  hasOwnProperty(x, prop) {
    return x != null && typeof x === 'object' && x.hasOwnProperty(prop);
  }





}
