import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SectionBusinessComponent } from './section-business/section-business.component';
import { CreditAppComponent } from './credit-app/credit-app.component';
import { InputTextComponent } from './input-text/input-text.component';
import { SectionBankingComponent } from './section-banking/section-banking.component';
import { SectionPrincipalComponent } from './section-principal/section-principal.component';
import { SectionEquipmentComponent } from './section-equipment/section-equipment.component';
import { SectionAgreementTextComponent } from './section-agreement-text/section-agreement-text.component';
import { SectionSignatureComponent } from './section-signature/section-signature.component';
import { HttpClientModule } from '@angular/common/http';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { InputDropdownComponent } from './input-dropdown/input-dropdown.component';
import { SimpleSignComponent } from './simple-sign/simple-sign.component';
import { HomeComponent } from './home/home.component' ;



@NgModule({
  declarations: [
    AppComponent,
    SectionBusinessComponent,
    CreditAppComponent,
    InputTextComponent,
    SectionBankingComponent,
    SectionPrincipalComponent,
    SectionEquipmentComponent,
    SectionAgreementTextComponent,
    SectionSignatureComponent,
    ModalConfirmComponent,
    InputDropdownComponent,
    SimpleSignComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
