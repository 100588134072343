import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-section-signature',
  templateUrl: './section-signature.component.html',
  styleUrls: ['./section-signature.component.css']
})
export class SectionSignatureComponent implements OnInit {



  @Input() values: any = {};
  @Output() valuesChange = new EventEmitter();
  @Input() expectedSignatures  = [];



  change() {
    this.valuesChange.emit(this.values);
  }

  today() {
    return new Date();
  }

  constructor() { }

  ngOnInit() {

  }

}
