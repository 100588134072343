import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-section-banking',
  templateUrl: './section-banking.component.html',
  styleUrls: ['./section-banking.component.css']
})
export class SectionBankingComponent implements OnInit {

  /*CurrentBank;
  CurrentBankAccount;
  CurrentBankOpened;
  CurrentBankPhone;
  CurrentBankContact;

  FinanceReference;
  FinanceReferenceOpened;
  FinanceReferencePhone;
  FinanceReferenceContact;*/

  @Input() values: any = {};
  @Output() valuesChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }
  change() {
    this.valuesChange.emit(this.values);
  }

}
