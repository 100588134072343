import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-section-agreement-text',
  templateUrl: './section-agreement-text.component.html',
  styleUrls: ['./section-agreement-text.component.css']
})
export class SectionAgreementTextComponent implements OnInit {


  @Input() values = {};
  @Output() valuesChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

}
