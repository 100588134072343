import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DateTime } from 'luxon';

declare let gtag: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'commercial-credit-app';
  copyrightYear = new Date().getFullYear();

  today = DateTime.local();
  maintenanceEndDate = DateTime.local(2023, 10, 16, 0, 0);

  maintenanceModalOpen = false;

  ngAfterViewInit() {
    if (this.today < this.maintenanceEndDate) {
      this.maintenanceModalOpen = true;
    }
  }

  closeMaintenanceModal() {
    this.maintenanceModalOpen = false;
  }

  constructor(private router: Router) {
    this.router.events.subscribe(x=> {
      if (x instanceof NavigationEnd) {
        gtag('config', 'G-CWT119JV1T', {
          page_path: (x as NavigationEnd).urlAfterRedirects
        });
      }
    });
  }
}
