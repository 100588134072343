import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    timer(5000).subscribe(x=> {
      window.location.href = "https://www.p1fs.com/equipment-financing";
    });
    
    
  }

}
