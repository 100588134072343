import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-section-equipment',
  templateUrl: './section-equipment.component.html',
  styleUrls: ['./section-equipment.component.css']
})
export class SectionEquipmentComponent implements OnInit {

  SellingCompany;
  Quantity;
  Year;
  Manufacturer;
  Model;
  Condition;
  EquipmentLocation;
  PurchaseReason;
  PurchaseType;
  AmountRequested;
  TermRequested;
  Titled;
  TitleState;
  HowDidYouHear;

  @Input() values: any = {};
  @Output() valuesChange = new  EventEmitter();

  change() {
    this.valuesChange.emit(this.values);
  }

  constructor() { }

  ngOnInit() {

  }



}
